.Site {
  display: flex;
  min-height: calc(100vh - 250px);
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

::-webkit-scrollbar {
  width: .3em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}

::-webkit-scrollbar-thumb:horizontal {
  display: none;
}
